<template>
	<Icon />
	<div
		class="
                    text-center
                    pt-5
                    md:pt-8
                    sm:pt-6
                    max-w-xs 
                    md:max-w-full 
                    mx-auto
                "
	>
		<h2>
			We Are Here For
			<span class="text-red font-bold">
				Your Business
			</span>
		</h2>
		<div class=" pt-5 sm:pt-6 md:pt-8">
			<p class="text-gray-500 text-center w-full">
				Reach out to us to see how we can help your business with our custom software solutions.
			</p>
		</div>
		<div class=" pt-5 sm:pt-6 md:pt-8">
			<a
				href="/contact"
				class="rounded-full tracking-wider w-full md:w-auto py-3 text-center text-white inline-block px-8 text-sm md:text-base uppercase bg-red font-semibold hover:bg-red-700 whitespace-nowrap"
				>Get in touch
			</a>
		</div>
	</div>
</template>

<script>
import Icon from "./Icon";
export default {
	components: {
		Icon,
	},
};
</script>

<style scoped></style>
