<template>
	<section class="justify-center w-full sm:flex hidden py-10">
		<div ></div >
	</section>
</template>

<style scoped>
section div  {
	width: 50px;
	border-top: 1px solid #ff5462;
}
</style>
