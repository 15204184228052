<template>
  <Main>
      <!-- <img src="@/assets/empty.svg"  alt="banner - indentations.io" style="width:1px; height: 0px; margin-left: 200vw;"> -->

    <section class="banner pt-28 md:pt-32 lg:pt-32 2xl:pt-48 md:px-10">
      <!-- <section class="banner  xs:px-10"> -->
      <div class="mx-auto w-full max-w-screen-xl pt-6 sm:pt-7 md:pt-0" style=" max-width: 100%;
   overflow: hidden;">
        <MainSection />
      </div>
    </section>

    <section class="px-6 xs:px-10 mt-16 sm:mt-16 md:mt-28 bg-circle" >
      <div class="max-w-screen-xl mx-auto flex flex-col justify-center">
        <SomeThingsWeDo />
      </div>
    </section>

    <div class="pt-20">
      <Divider />
    </div>

    <section class="px-6 xs:px-10">
      <div
        class="w-full max-w-screen-xl mx-auto justify-center flex flex-col items-center"
      >
        <WeAreHereForYourBusiness />
      </div>
    </section>

    <div class="pt-16 sm:pt-24"></div>

    <section class="bg-gray-100 px-6 xs:px-10 relative">
      <div
        class="text-center mx-auto max-w-screen-xl pt-12 pb-10 sm:pt-20 sm:pb-16"
      >
        <SomeOfOurHappyClients />
      </div>
    </section>
  </Main>
</template>

<script>
import Main from "@/components/Main";
import Divider from "@/components/Divider";
import SomeOfOurHappyClients from "./SomeOfOurHappyClients";

import MainSection from "./MainSection";
import SomeThingsWeDo from "./someThingsWeDo/Index";
import WeAreHereForYourBusiness from "./weAreHereForYourBusiness/Index";

export default {
  components: {
    Divider,
    Main,
    MainSection,
    SomeThingsWeDo,
    WeAreHereForYourBusiness,
    SomeOfOurHappyClients,
  },

  mounted() {},
};
</script>
<style scoped>

</style>
