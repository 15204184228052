<template>
	<h2>
		Some Of
		<span class="text-red  font-bold whitespace-nowrap">
			Our Happy Clients
		</span>
	</h2>
	<ClientCarousel />
	<a href="/" class="hidden">
		<h6
			class="text-red text-center flex gap-2 justify-center tracking-wider items-center"
		>
			<svg
				width="28"
				height="22"
				class="hidden lg:inline"
				viewBox="0 0 28 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 11.1811C1 11.1811 5.72726 1.72656 14 1.72656C22.2727 1.72656 26.9999 11.1811 26.9999 11.1811C26.9999 11.1811 22.2727 20.6356 14 20.6356C5.72726 20.6356 1 11.1811 1 11.1811Z"
					stroke="#FE5362"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M14 14.7258C15.9581 14.7258 17.5455 13.1385 17.5455 11.1804C17.5455 9.2223 15.9581 7.63495 14 7.63495C12.0419 7.63495 10.4546 9.2223 10.4546 11.1804C10.4546 13.1385 12.0419 14.7258 14 14.7258Z"
					stroke="#FE5362"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<svg
				width="5"
				class="inline lg:hidden"
				height="10"
				viewBox="0 0 5 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.50706 5.00766C1.54948 5.05624 1.59191 5.09268 1.62372 5.12912C1.81463 5.34775 2.00554 5.56638 2.18585 5.77287C3.05554 6.76885 3.92524 7.76484 4.79493 8.76083C4.89039 8.87014 4.95402 8.99161 4.98584 9.13736C5.07069 9.59891 4.76311 10.0119 4.36008 9.99974C4.19039 9.99974 4.05251 9.92686 3.92524 9.79325C3.6813 9.51389 3.44796 9.24668 3.20403 8.96731C2.19645 7.82557 1.19948 6.67168 0.19191 5.52994C0.0646373 5.38419 -0.0096044 5.202 0.00100187 4.98337C0.0116077 4.78903 0.0752435 4.61898 0.19191 4.48537C0.637363 3.97523 1.08282 3.46509 1.52827 2.95495C2.05857 2.34764 2.58888 1.74033 3.11918 1.13302C3.38433 0.829369 3.64948 0.525714 3.91463 0.22206C4.08433 0.0277205 4.29645 -0.0451564 4.52978 0.0277214C4.76311 0.100598 4.9116 0.270645 4.96463 0.537861C5.01766 0.792932 4.96463 1.01156 4.81614 1.2059C4.80554 1.21805 4.79493 1.24234 4.77372 1.25449C3.90402 2.25047 3.03433 3.24646 2.16463 4.24245C1.95251 4.48537 1.751 4.71615 1.53888 4.95907C1.53888 4.97122 1.52827 4.98336 1.50706 5.00766Z"
					fill="#FF4958"
				/>
			</svg>

			SEE MORE

			<svg
				width="5"
				class="inline lg:hidden"
				height="10"
				viewBox="0 0 5 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3.49294 4.99234C3.45052 4.94376 3.40809 4.90732 3.37627 4.87088C3.18537 4.65225 2.99446 4.43362 2.81415 4.22713C1.94446 3.23115 1.07476 2.23516 0.205069 1.23917C0.109614 1.12986 0.0459776 1.00839 0.0141595 0.862641C-0.0706888 0.401086 0.236887 -0.0118848 0.639916 0.000261384C0.809613 0.000261384 0.947491 0.0731386 1.07476 0.206747C1.3187 0.486109 1.55204 0.753325 1.79597 1.03269C2.80355 2.17443 3.80052 3.32832 4.80809 4.47006C4.93536 4.61581 5.0096 4.798 4.999 5.01663C4.98839 5.21097 4.92476 5.38102 4.80809 5.51463C4.36264 6.02477 3.91718 6.53491 3.47173 7.04505C2.94143 7.65236 2.41112 8.25967 1.88082 8.86698C1.61567 9.17063 1.35052 9.47429 1.08537 9.77794C0.915673 9.97228 0.703552 10.0452 0.47022 9.97228C0.236887 9.8994 0.0884019 9.72935 0.0353717 9.46214C-0.0176585 9.20707 0.0353718 8.98844 0.183856 8.7941C0.194462 8.78195 0.205068 8.75766 0.22628 8.74551C1.09598 7.74953 1.96567 6.75354 2.83537 5.75755C3.04749 5.51463 3.249 5.28385 3.46112 5.04093C3.46112 5.02878 3.47173 5.01664 3.49294 4.99234Z"
					fill="#FF4958"
				/>
			</svg>
		</h6>
	</a>
</template>

<script>
import ClientCarousel from "./ClientCarousel";
export default {
	components: { ClientCarousel },
};
</script>

<style scoped></style>
