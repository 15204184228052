<template>
   <div class="block md:flex main-section mx-auto justify-center">
      <div class="main-section-content max-w-screen-xl">
         <div class="item md:max-w-full text-white w-full mx-auto">
            <h1 class="text-center pt-0 md:pt-14">
               <span> Welcome to</span><br />
               <a href="/">
                  <span class="text-red block font-bold xs:mt-2 lg:mt-4">
                     Indentations.io
                  </span>
               </a>
            </h1>

            <h3 class="text-center mt-3 xs:mt-5 lg:mt-5">
               We build cool stuff for our <br class="block sm:hidden" />amazing
               clients.
            </h3>
         </div>
         <div class="item relative pt-3 sm:pt-4 md:pt-0 flex justify-center">
            <div class="inline-block relative mx-auto w-full">
               <div class="frames-container md:w-full">
                  <svg
                     class="invisible hidden-container"
                     width="593"
                     height="549"
                     viewBox="0 0 593 549"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  ></svg>

                  <div class="frames-animation" :class="loaded && 'animated'">
                     <div class="frame table">
                        <img
                           src="@/assets/animations/table.svg"
                           alt="banner - indentations.io"
                        />
                     </div>
                     <div class="frame code">
                        <img
                           src="@/assets/animations/code.png"
                           alt="banner - indentations.io"
                        />
                     </div>
                     <div class="frame button">
                        <img
                           src="@/assets/animations/button.svg"
                           alt="banner - indentations.io"
                        />
                     </div>
                     <div class="frame button-hover">
                        <img
                           src="@/assets/animations/button-hover.svg"
                           alt="banner - indentations.io"
                        />
                     </div>

                     <div class="frame cursor">
                        <img
                           src="@/assets/animations/cursor.svg"
                           alt="banner - indentations.io"
                        />
                     </div>
                     <div class="frame hand">
                        <img
                           src="@/assets/animations/hand.svg"
                           alt="banner - indentations.io"
                        />
                     </div>
                     <div class="frame person">
                        <img
                           src="@/assets/animations/person.svg"
                           alt="banner - indentations.io"
                        />
                     </div>

                     <div class="frame loader">
                        <svg
                           width="593"
                           height="549"
                           viewBox="0 0 593 548"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M278.749 257.124C277.406 257.859 276.429 259.697 276.429 261.167C276.429 262.637 277.528 263.249 278.749 262.514C280.093 261.779 281.07 259.942 281.07 258.471C281.07 257.001 279.971 256.389 278.749 257.124Z"
                              fill="#455A64"
                           />
                           <path
                              d="M209.369 180.078C208.881 180.813 208.636 181.793 208.636 182.528V288.125L203.995 285.43V179.833C203.995 179.098 204.239 178.118 204.727 177.383L209.369 180.078Z"
                              fill="#263238"
                           />
                           <path
                              d="M348.74 101.048C348.74 100.934 348.74 100.934 348.74 100.82C348.74 100.706 348.74 100.706 348.74 100.592C348.74 100.478 348.626 100.364 348.626 100.364C348.626 100.364 348.626 100.25 348.512 100.25C348.512 100.25 348.512 100.136 348.399 100.136C348.399 100.022 348.285 100.022 348.285 100.022C348.171 100.022 348.171 99.9077 348.057 99.9077C347.603 99.6796 347.148 99.7937 346.579 100.136L210.924 178.477C209.673 179.161 208.65 180.872 208.65 182.355V293.555V294.012C208.65 294.582 208.991 295.152 209.446 295.38C209.446 295.38 209.56 295.38 209.56 295.494C209.56 295.494 209.673 295.494 209.673 295.608C209.787 295.608 209.787 295.608 209.901 295.722C210.015 295.722 210.128 295.722 210.128 295.722C210.356 295.722 210.583 295.608 210.811 295.494H210.924L213.085 294.24L346.579 217.039C347.83 216.355 348.854 214.644 348.854 213.161V101.39C348.854 101.162 348.854 101.048 348.74 101.048Z"
                              fill="#272822"
                           />
                           <path
                              d="M348.859 101.298C348.859 99.9298 347.836 99.3596 346.699 100.044L211.036 178.366C209.785 179.05 208.762 180.761 208.762 182.243V189.542L348.859 108.597V101.298Z"
                              fill="#6360FE"
                           />
                           <path
                              d="M213.769 179.962C213.086 180.418 212.518 181.559 212.518 182.585C212.518 183.612 213.086 184.182 213.769 183.84C214.451 183.384 215.02 182.243 215.02 181.217C215.02 180.076 214.451 179.506 213.769 179.962Z"
                              fill="#455A64"
                           />
                           <path
                              d="M218.205 177.345C217.523 177.801 216.954 178.942 216.954 179.968C216.954 180.995 217.523 181.565 218.205 181.223C218.888 180.766 219.456 179.626 219.456 178.6C219.57 177.459 219.001 176.889 218.205 177.345Z"
                              fill="#455A64"
                           />
                           <path
                              d="M222.751 174.722C222.069 175.178 221.5 176.319 221.5 177.345C221.5 178.372 222.069 178.942 222.751 178.6C223.433 178.144 224.002 177.003 224.002 175.977C224.002 174.95 223.433 174.266 222.751 174.722Z"
                              fill="#455A64"
                           />
                           <rect
                              width="141.195"
                              height="88.875"
                              rx="2.28471"
                              transform="matrix(0.866025 -0.5 0 1 209.367 189.359)"
                              fill="#272822"
                           />

                           <circle
                              cx="2.24319"
                              class="loader-circle-1"
                              cy="2.24319"
                              r="2.24319"
                              transform="matrix(0.859406 -0.511293 0 1 270.041 221.773)"
                              fill="#EF4D5B"
                           />
                           <circle
                              cx="2.24319"
                              class="loader-circle-2"
                              cy="2.24319"
                              r="2.24319"
                              transform="matrix(0.859406 -0.511293 0 1 276.141 217.906)"
                              fill="#EF4D5B"
                           />
                           <circle
                              cx="2.24319"
                              class="loader-circle-3"
                              cy="2.24319"
                              r="2.24319"
                              transform="matrix(0.859406 -0.511293 0 1 282.24 214.516)"
                              fill="#EF4D5B"
                           />
                           <circle
                              cx="2.24319"
                              class="loader-circle-4"
                              cy="2.24319"
                              r="2.24319"
                              transform="matrix(0.859406 -0.511293 0 1 288.342 210.883)"
                              fill="#EF4D5B"
                           />
                           <path
                              d="M279.078 204.545C282.959 202.243 285.974 196.84 285.812 192.478C285.65 188.116 282.373 186.446 278.492 188.749C274.611 191.051 271.596 196.454 271.758 200.816C271.92 205.178 275.197 206.848 279.078 204.545Z"
                              fill="#EF4D5B"
                           />
                           <path
                              d="M279.926 191.046L274.534 194.244C274.263 194.405 274.052 194.782 274.063 195.087L274.107 196.274C274.119 196.579 274.348 196.695 274.619 196.534L280.011 193.336C280.282 193.175 280.492 192.798 280.481 192.494L280.437 191.306C280.426 191.002 280.197 190.885 279.926 191.046Z"
                              fill="white"
                           />
                           <path
                              d="M281.209 193.979L275.817 197.178C275.546 197.338 275.335 197.716 275.347 198.02L275.391 199.208C275.402 199.512 275.631 199.629 275.902 199.468L281.294 196.27C281.565 196.109 281.775 195.732 281.764 195.427L281.72 194.24C281.709 193.935 281.48 193.819 281.209 193.979Z"
                              fill="white"
                           />
                           <path
                              d="M282.929 196.636L277.538 199.834C277.267 199.995 277.056 200.372 277.067 200.677L277.111 201.864C277.123 202.169 277.352 202.285 277.623 202.124L283.014 198.926C283.286 198.765 283.496 198.388 283.485 198.083L283.441 196.896C283.43 196.591 283.201 196.475 282.929 196.636Z"
                              fill="white"
                           />
                           <path
                              d="M265.055 204.79C265.907 203.275 266.725 201.808 267.554 200.345C267.616 200.242 267.665 200.132 267.696 200.023C267.726 199.914 267.739 199.809 267.733 199.714C267.68 198.293 267.627 196.87 267.575 195.448C267.536 194.756 267.624 193.994 267.834 193.206C268.044 192.418 268.372 191.622 268.797 190.865C269.221 190.107 269.735 189.404 270.307 188.798C270.879 188.192 271.497 187.695 272.124 187.337L274.828 185.733L274.907 187.882L272.267 189.448C270.559 190.462 269.427 192.469 269.491 194.376C269.555 196.083 269.618 197.79 269.681 199.495C269.687 199.59 269.675 199.696 269.644 199.805C269.613 199.914 269.565 200.024 269.502 200.127C268.994 201.015 268.499 201.91 267.995 202.8C267.917 202.918 267.831 203.03 267.74 203.133C267.869 203.217 267.927 203.248 267.989 203.29C268.539 203.568 269.085 203.851 269.636 204.116C269.705 204.144 269.761 204.197 269.799 204.27C269.836 204.342 269.855 204.434 269.854 204.537C269.917 206.234 269.98 207.931 270.043 209.628C270.096 211.062 270.814 211.797 271.966 211.56C272.295 211.486 272.636 211.349 272.977 211.154C273.769 210.713 274.559 210.215 275.35 209.746L275.686 209.546L275.765 211.664C275.67 211.72 275.568 211.796 275.485 211.846L272.942 213.355C271.723 214.073 270.535 214.225 269.636 213.777C268.737 213.33 268.199 212.319 268.14 210.965C268.078 209.499 268.023 208.028 267.976 206.553C267.976 206.455 267.956 206.369 267.919 206.3C267.881 206.232 267.827 206.184 267.76 206.159C266.936 205.746 266.112 205.333 265.289 204.92C265.219 204.891 265.148 204.843 265.055 204.79Z"
                              fill="#0178B2"
                           />
                           <path
                              d="M282.841 207.576L282.762 205.433C282.872 205.368 282.973 205.308 283.073 205.248C283.994 204.702 284.918 204.188 285.832 203.568C286.493 203.106 287.097 202.394 287.525 201.573C287.952 200.753 288.172 199.883 288.141 199.136C288.074 197.352 288.008 195.57 287.942 193.785C287.943 193.611 287.997 193.414 288.095 193.228C288.595 192.306 289.111 191.393 289.621 190.478C289.685 190.363 289.747 190.245 289.83 190.092C289.239 189.797 288.672 189.492 288.083 189.228C287.973 189.187 287.884 189.106 287.823 188.993C287.762 188.879 287.731 188.736 287.733 188.573C287.689 186.906 287.61 185.261 287.549 183.606C287.479 181.739 286.267 181.115 284.604 182.088C283.816 182.556 283.027 183.024 282.238 183.492L281.956 183.66L281.878 181.558C281.907 181.521 281.92 181.495 281.936 181.486C283.136 180.803 284.347 179.98 285.533 179.469C287.715 178.53 289.387 179.812 289.478 182.277C289.533 183.755 289.588 185.234 289.643 186.71C289.642 186.804 289.659 186.888 289.693 186.954C289.728 187.021 289.778 187.069 289.84 187.096C290.723 187.535 291.603 187.981 292.479 188.431C292.509 188.45 292.536 188.472 292.562 188.495C292.5 188.612 292.44 188.727 292.373 188.834C291.595 190.229 290.814 191.621 290.044 193.021C289.951 193.201 289.901 193.391 289.905 193.556C289.948 195.051 290.015 196.533 290.07 198.02C290.114 199.39 289.677 200.986 288.853 202.461C288.029 203.936 286.886 205.171 285.671 205.898L282.841 207.576Z"
                              fill="#0178B2"
                           />
                        </svg>
                     </div>
                     <div class="frame icons">
                        <img
                           src="@/assets/animations/icon-1.png"
                           alt="banner - indentations.io"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         loaded: false,
      };
   },

   mounted() {
      window.onload = () => {
       const el = document.getElementById("loader-container");
            el.classList.add("hidden");
         
         setTimeout(() => {
            this.loaded = true;
         }, 400);
      };
      // document.onreadystatechange = () => {

      // };
   },
};
</script>
<style scoped lang="scss">
/* banner laptop images */
.main-section {
   .main-section-content {
      display: grid;
      grid-template-columns: 1fr;
      .item:nth-child(2) {
         padding-right: 10px;
         padding-left: 10px;
      }
      .frames-container {
         width: 90vw;
         max-width: 450px;
         margin-right: auto;
         margin-left: auto;
      }
   }
   h3 {
      white-space: nowrap;
      line-height: 150%;
   }
}
@media (max-width: 400px) {
   .main-section {
      h3 {
         font-size: 0.9rem;
      }
   }
}

// @media(min-width: 320px) {
//    .hide-on-320 {
//       display: none;
//    }
// }

@keyframes hand-animation {
   from {
      transform: translateX(0) translateY(0);
   }
   to {
      transform: translateX(-2%) translateY(3%);
   }
}

@keyframes cursor-animation {
   from {
      opacity: 1;
      transform: translateX(0) translateY(0);
   }
   to {
      opacity: 1;
      transform: translateX(-4%) translateY(4%);
   }
}

@keyframes code-animation {
   0% {
      opacity: 1;
   }
   99% {
      opacity: 1;
   }
   100% {
      opacity: 1;
   }
}

@keyframes loader-animation {
   0% {
      opacity: 1;
   }
   50% {
      opacity: 1;
   }
   100% {
      opacity: 0;
   }
}
.frames-container {
   position: relative;
   .hidden-container {
      width: 100%;
      height: auto;
   }
   .frames-animation {
      .frame {
         width: 100%;
      }
   }
}

@keyframes icons-animation {
   0% {
      opacity: 0;
      transform: scale(1) perspective(200px) translate3d(-40%, -210%, -200rem);
   }
   100% {
      transform: scale(1) perspective(200px) translate3d(0, 0, 0);
      opacity: 1;
   }
}

@keyframes hide-item {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 0;
   }
}

@keyframes loader-circle {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.frames-animation {
   display: flex;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 100%;
   animation: cont-animation 6s infinite;

   .frame {
      position: absolute;
      svg,
      img {
         height: auto;
         width: 100%;
      }
   }

   .icons,
   .loader,
   .button-hover {
      opacity: 0;
   }
   .icons {
      // opacity: 1;
   }
   .loader {
      .loader-circle-1 {
         animation: loader-circle 0.5s linear 0.2s infinite;
      }
      .loader-circle-2 {
         animation: loader-circle 0.5s linear 0.4s infinite;
      }
      .loader-circle-3 {
         animation: loader-circle 0.5s linear 0.6s infinite;
      }
      .loader-circle-4 {
         animation: loader-circle 0.5s linear 0.8s infinite;
      }
   }
}

.frames-animation.animated {
   .hand {
      animation: hand-animation 600ms;
      animation-delay: 0s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
   }
   .loader {
      animation: loader-animation 700ms;
      animation-delay: 1s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
   }
   .code {
      animation: code-animation 1s;
      animation-delay: 0s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
   }
   .button {
      animation: hide-item 0s;
      animation-delay: 1s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
   }
   .button-hover {
      animation: loader-circle 0.2s, hide-item 0s;
      animation-delay: 0.4s, 1.1s;
      animation-timing-function: ease, ease;
      animation-fill-mode: forwards, forwards;
   }
   .cursor {
      animation: cursor-animation 600ms, hide-item 0s;
      animation-delay: 0s, 1.1s;
      animation-timing-function: ease, ease;
      animation-fill-mode: forwards, forwards;
      opacity: 0;
   }

   .icons {
      animation: icons-animation 700ms;
      animation-delay: 1.7s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
   }
}

@media (min-width: 800px) {
   .main-section {
      .main-section-content {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         .frames-container {
            width: 100%;
            max-width: 100%;
         }
         h1,
         span,
         h3 {
            text-align: left;
         }
         .item:nth-child(2) {
            margin-top: 0;
         }
      }
   }
}
// @keyframes frame-animation {
//    100% {
//       transform: translateX(-100%);
//       opacity: 1;
//    }
// }

// $time: 10s;
// $number-of-frames: 4;
// $frame-duration: calc($time / $number-of-frames);
// .frames-container {
//    overflow: hidden;
//    position: relative;
//    transform: translateY(-50px);
//    .frames-animation {
//       display: flex;
//       position: absolute;
//       top: 0;
//       left: 0;
//       height: 100%;
//       width: calc($number-of-frames * 100%);
//       animation: frame-animation $time infinite;
//       animation-timing-function: steps($number-of-frames);

//       .frame {
//          display: block;
//          height: 100%;
//          width: calc(100% / $number-of-frames);
//          max-width: calc(100% / $number-of-frames);
//          flex-basis: calc(100% / $number-of-frames);
//       }
//    }
// }
</style>
