<template>
  <div class="py-12 gap-4" id="client-carousel">
    <div class="flex items-center justify-center">
      <a href="https://kingdavidcenterrehab.com/" target="false"></a
      ><a href="https://kingdavidcenterrehab.com/" target="_blank"
        ><img
          src="@/assets/clients/king-david-center.png"
          alt="client - indentations.io"
      /></a>
    </div>
    <div class="flex items-center justify-center">
      <a href="javascript:void(0)"></a
      ><a href="javascript:void(0)"
        ><img
          src="@/assets/clients/aron_rovner.png"
          alt="client - indentations.io"
      /></a>
    </div>
    <div class="flex items-center justify-center">
      <a href="https://elitecarcleaning.com/" target="false"></a
      ><a href="https://elitecarcleaning.com/" target="_blank"
        ><img
          src="@/assets/clients/elitecarcleaning.png"
          alt="client - indentations.io"
      /></a>
    </div>
    <div class="flex items-center justify-center">
      <a href="https://www.kerenaniyem.com/" target="false"></a
      ><a href="https://www.kerenaniyem.com/" target="_blank"
        ><img
          alt="client - indentations.io"
          src="@/assets/clients/kerenaniyem.png"
      /></a>
    </div>
    <div class="flex items-center justify-center">
      <a href="https://twitter.com/rebbejokes" target="false"></a
      ><a href="https://twitter.com/rebbejokes" target="_blank"
        ><img
          src="@/assets/clients/rebbejokes.png"
          alt="client - indentations.io"
      /></a>
    </div>
    <div class="flex items-center justify-center">
      <a href="https://www.cncresults.com/" target="false"></a
      ><a href="https://www.cncresults.com/" target="_blank"
        ><img
          src="@/assets/clients/cncresults.png"
          alt="client - indentations.io"
      /></a>
    </div>
    <div class="flex items-center justify-center">
      <a href="https://wedgewoodcapital.llc/" target="false"></a
      ><a href="https://wedgewoodcapital.llc/" target="_blank"
        ><img
          src="@/assets/clients/wedgewood.svg"
          alt="client - indentations.io"
      /></a>
    </div>

    <!-- <template v-for="image in images">
      <div class="flex items-center justify-center">
        <a :href="image.link" target="false"></a>
        <template v-if="image.link === '#'">
            <img v-bind:src="image.src" alt="client - indentations.io" />
        </template>
        <template v-else>
          <a :href="image.link" target="_blank">
            <img v-bind:src="image.src" alt="client - indentations.io"  />
          </a>
        </template>
      </div>
    </template> -->
  </div>
</template>

<script>
import elitecarcleaning from "../../assets/clients/elitecarcleaning.png";
import kerenaniyem from "../../assets/clients/kerenaniyem.png";
import rebbejokes from "../../assets/clients/rebbejokes.png";
import aron_rovner from "../../assets/clients/aron_rovner.png";
import cncresults from "../../assets/clients/cncresults.png";
import wedgewoodCapital from "../../assets/clients/wedgewood.svg";
import kingDavidCenter from "../../assets/clients/king-david-center.png";

export default {
  name: "ClientCarousel",
  components: {},
  data() {
    return {
      images: [
        { src: kingDavidCenter, link: "https://kingdavidcenterrehab.com/" },
        { src: aron_rovner, link: "javascript:void(0)" },
        { src: elitecarcleaning, link: "https://elitecarcleaning.com/" },
        { src: kerenaniyem, link: "https://www.kerenaniyem.com/" },
        { src: rebbejokes, link: "https://twitter.com/rebbejokes" },
        { src: cncresults, link: "https://www.cncresults.com/" },
        { src: wedgewoodCapital, link: "https://wedgewoodcapital.llc/" },
      ],
    };
  },

  mounted() {},

  computed: {},
};
</script>
<style scoped lang="scss">
#client-carousel {
  width: auto;
  grid-template-columns: repeat(8, 1fr);
  display: inline-grid;
  div {
    grid-column: span 2;
    &:nth-child(5) {
      grid-column-end: -6;
    }
    &:nth-child(6) {
      grid-column-end: -4;
    }
    &:nth-child(7) {
      grid-column-end: -2;
    }
  }
}

@media (min-width: 580px) {
  #client-carousel {
    grid-template-columns: repeat(10, 38px);
    div {
      grid-column: span 2;
      &:nth-child(5) {
        grid-column-end: unset;
      }
      &:nth-child(6) {
        grid-column-end: -6;
      }
      &:nth-child(7) {
        grid-column-end: -4;
      }
    }
  }
}

@media (min-width: 640px) {
  #client-carousel {
    grid-template-columns: repeat(10, 40px);
  }
}

@media (min-width: 750px) {
  #client-carousel {
    grid-template-columns: repeat(10, 45px);
  }
}

@media (min-width: 985px) {
  #client-carousel {
    grid-template-columns: repeat(7, 1fr);
    div {
      grid-column: span 1;
      &:nth-child(5) {
        grid-column-end: unset;
      }
      &:nth-child(6) {
        grid-column-end: unset;
      }
      &:nth-child(7) {
        grid-column-end: unset;
      }
    }
  }
}
</style>
